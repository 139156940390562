import { Box, Button } from '@mui/material';
import Page from 'component/new_design/layout/Page';
import { SitesTable } from '../partial/tables/sites/SitesTable';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { CreateSiteModal } from '../partial/CreateSite/CreateSiteModal';
import { useState } from 'react';
import { linkHelper } from 'linkHelper';

export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isCreateSiteOpen, setIsCreateSiteOpen] = useState(false);

  return (
    <>
      <Page title="Dashboard">
        <Box>{'this is the dashboard'}</Box>
        <SitesTable enablePagination={false} enableSearch={false}>
          <Button
            onClick={() => navigate(linkHelper.newDesign.sites.getLink())}
            size="medium"
            variant="outlined"
          >
            {t('see_all')}
          </Button>
          <Button
            onClick={() => setIsCreateSiteOpen(true)}
            size="medium"
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            {t('create_site')}
          </Button>
        </SitesTable>
      </Page>

      {isCreateSiteOpen ? <CreateSiteModal onCancel={() => setIsCreateSiteOpen(false)} /> : null}
    </>
  );
}

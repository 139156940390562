import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SiteDetailsFormValue, SiteDetailsStep } from './SiteDetailsStep';
import { WordpressInfoFormValue, WordpressInformationStep } from './WordpressInformationStep';
import { Step } from 'component/new_design/base/Step';
import { isManagedHosting, useGetToken } from 'utils/token';
import { useCreatePartnerSite, useCreateSite } from 'api/site';
import { useNavigate } from 'react-router-dom';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import Close from 'component/new_design/icons/Close.svg?react';
import { cssVar } from 'utils/css';

const WOOCOMMERCE_PLUGIN = 'woocommerce';
const RECOMMENDED_PLUGINS = [
  'coming-soon',
  'google-site-kit',
  'optinmonster',
  'wpforms-lite',
  'wp-mail-smtp',
];
const ATARIM_PLUGIN =
  'https://atarim.io/wp-content/uploads/atarim-client-interface-wpplugin-3.2.1.zip';

const PARTNER_SITE_DOMAIN = 'wpdns.site';

interface Props {
  readonly onCancel: () => void;
}

export const CreateSiteModal = ({ onCancel }: Props) => {
  const { t } = useTranslation();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [siteDetailsData, setSiteDetailsData] = useState<SiteDetailsFormValue | null>(null);

  const navigate = useNavigate();
  const token = useGetToken();
  const isManaged = isManagedHosting(token);
  const createPartnerSite = useCreatePartnerSite();
  const createSite = useCreateSite();

  const isSubmitting = createSite.isPending || createPartnerSite.isPending;

  const processSiteDetailsData = (data: SiteDetailsFormValue) => {
    setSiteDetailsData(data);
  };

  const processWordpressInfoData = async (data: WordpressInfoFormValue) => {
    if (!siteDetailsData) {
      throw new Error('Site details data is missing');
    }

    const installedPlugins = [];

    if (data.wooCommerce) {
      installedPlugins.push(WOOCOMMERCE_PLUGIN);
    }
    if (data.wpRecommendedPlugins) {
      installedPlugins.push(...RECOMMENDED_PLUGINS);
    }
    if (data.atarim) {
      installedPlugins.push(ATARIM_PLUGIN);
    }

    const baseData = {
      name: siteDetailsData.siteName,
      label: siteDetailsData.siteName,
      admin_username: data.username,
      admin_password: data.password,
      admin_email: data.email,
      location: siteDetailsData.isRestrictedLocation ? undefined : Number(siteDetailsData.location),
      restricted_location: siteDetailsData.isRestrictedLocation
        ? Number(siteDetailsData.location)
        : undefined,
      multisite: data.multisite,
      template_id: siteDetailsData.templateId || undefined,
      install_plugins: siteDetailsData.templateId ? undefined : installedPlugins.join(','),
    };

    let response;
    if (isManaged) {
      response = await createSite.mutateAsync(baseData);
    } else {
      response = await createPartnerSite.mutateAsync({ ...baseData, domain: PARTNER_SITE_DOMAIN });
    }
    navigate(`/sites/${response.data?.result?.id ?? ''}`);
  };

  const onStepComplete = async (data: SiteDetailsFormValue | WordpressInfoFormValue) => {
    if (activeStepIndex === 0) {
      processSiteDetailsData(data as SiteDetailsFormValue);
    } else {
      await processWordpressInfoData(data as WordpressInfoFormValue);
    }
    setActiveStepIndex(activeStepIndex + 1);
  };

  const steps = [
    {
      title: t('create_site_wizard_step_one_title'),
      description: t('create_site_wizard_step_one_description'),
      formDescription: t('create_site_wizard_step_one_form_title'),
      component: SiteDetailsStep,
    },
    {
      title: t('create_site_wizard_step_two_title'),
      description: t('create_site_wizard_step_two_description'),
      formDescription: t('create_site_wizard_step_two_form_title'),
      component: WordpressInformationStep,
    },
  ];

  const isLastStep = activeStepIndex === steps.length - 1;
  const activeStepFormId = `step-${activeStepIndex}`;
  const ActiveStepComponent = steps[activeStepIndex].component;
  const activeStepTitle = steps[activeStepIndex].title;
  const activeStepFormDescription = steps[activeStepIndex].formDescription;

  const getStepStatus = (index: number) => {
    if (index === activeStepIndex) {
      return 'active';
    } else if (index < activeStepIndex) {
      return 'success';
    }
    return 'default';
  };

  return (
    <Dialog
      open
      maxWidth="lg"
      PaperProps={{ sx: { minWidth: [undefined, undefined, '800px', '1080px'] } }}
    >
      <DialogTitle>
        {t('create_site')}
        <IconButton onClick={onCancel} sx={{ color: cssVar('--color-grey-500') }} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <Stack
        direction={['column', 'column', 'row']}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack direction="column" p={4} gap={4} sx={{ maxWidth: [undefined, undefined, '288px'] }}>
          {steps.map((step, index) => (
            <Step
              key={step.title}
              number={index + 1}
              title={step.title}
              description={step.description}
              status={getStepStatus(index)}
            />
          ))}
        </Stack>
        <Stack direction="column" divider={<Divider flexItem />}>
          <Box p={4}>
            <Typography variant="h5" sx={{ color: cssVar('--color-grey-900') }}>
              {activeStepTitle}
            </Typography>
            <Typography mb={4} sx={{ color: cssVar('--color-grey-500') }}>
              {activeStepFormDescription}
            </Typography>
            <ActiveStepComponent
              onComplete={onStepComplete}
              formId={activeStepFormId}
              hasTemplateId={!!siteDetailsData?.templateId}
            />
          </Box>
          <Stack p={4} gap={3} direction="row" flex="none">
            <ProgressiveButton type="submit" form={activeStepFormId} isLoading={isSubmitting}>
              {isLastStep ? t('create') : t('continue')}
            </ProgressiveButton>
            <Button onClick={onCancel}>{t('cancel')}</Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

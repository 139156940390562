import { Box, Link, Typography } from '@mui/material';
import Flag from 'react-flagpack';
import { useLocations, Location } from 'api/locations';
import { useSiteTemplates } from 'api/site';
import { TextField, Options } from 'component/base/TextField';
import { linkHelper } from 'linkHelper';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export interface SiteDetailsFormValue {
  readonly siteName: string;
  readonly location: string;
  readonly templateId: string;
  readonly isRestrictedLocation: boolean;
}

interface Props {
  readonly onComplete: (data: SiteDetailsFormValue) => void;
  readonly formId: string;
}

export const SiteDetailsStep = ({ onComplete, formId }: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const methods = useForm<SiteDetailsFormValue>({
    defaultValues: {
      siteName: '',
      location: undefined,
      templateId: searchParams.get('templateId') || '',
      isRestrictedLocation: false,
    },
    mode: 'onChange',
  });

  const { data: siteTemplatesData, isLoading } = useSiteTemplates();

  const { data } = useLocations();
  const locations = data?.data.result.locations ?? [];
  const enterpriseLocations = data?.data.result.restricted_locations ?? [];
  const enterpriseLocationIds = enterpriseLocations.map(location => String(location.id));

  const mapLocation = ({ id, location }: Location) => {
    const locationSplit = location.split(' - ');
    return {
      value: String(id),
      label: (
        <>
          <Flag code={locationSplit[0]} size="m" />
          <Box marginLeft={1}>{location}</Box>
        </>
      ),
    };
  };

  let locationOptions: Options = [];
  if (enterpriseLocations?.length) {
    locationOptions = [
      {
        sectionName: t('enterprise_locations'),
        options: enterpriseLocations.map(mapLocation),
      },
      {
        sectionName: t('standard_locations'),
        options: locations.map(mapLocation),
      },
    ];
  } else {
    locationOptions = locations.map(mapLocation) ?? [];
  }

  locationOptions.unshift({
    value: '',
    label: t('choose_location'),
  });

  const templateOptions: Options =
    siteTemplatesData?.data.result?.site_templates?.map(template => ({
      value: template.template_id ?? '',
      label: template.name ?? '',
    })) ?? [];

  templateOptions.unshift({
    value: '',
    label: t('select_template'),
  });

  const noTemplatesAvailable = !isLoading && !templateOptions.length;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onComplete)} id={formId}>
        <TextField
          name="siteName"
          label={t('site_name_label')}
          placeholder={String(t('site_name_label_placeholder'))}
          fullWidth
          rules={{
            required: true,
          }}
          endNode={<Typography variant="body2">{t('site_name_label_description')}</Typography>}
        />
        <TextField
          name="location"
          label={t('select_location')}
          fullWidth
          options={locationOptions}
          handleSelect={value => {
            methods.setValue('isRestrictedLocation', enterpriseLocationIds.includes(value));
          }}
          rules={{
            required: true,
            validate: (val: string) => {
              if (val === '-1') {
                return 'Select a location';
              }
            },
          }}
        />
        <TextField
          name="templateId"
          label={t('build_from_template')}
          fullWidth
          options={templateOptions}
          handleSelect={value => {
            methods.setValue('templateId', value);
          }}
          isLoading={isLoading}
          disabled={noTemplatesAvailable}
        />
        {!!noTemplatesAvailable && (
          <Box marginTop={2}>
            <Typography variant="body1" fontWeight="bold">
              {t('create_template_site_create_title')}
            </Typography>
            <Typography variant="body1" color="gray">
              <Trans
                i18nKey="create_template_site_create_description"
                components={{
                  siteTemplatesLink: (
                    <Link
                      onClick={() => {
                        navigate(linkHelper.sites.templates.getLink());
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};
